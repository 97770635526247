<template>
	<div>
		<Breadcrumbs main="" title="reuse.header"/>
		<!-- Container-fluid starts-->
		<div class="container-fluid search-page">
			<div class="row">
                
                <div class="col-sm-6">
                    <form class="theme-form">
                        <div class="ml-1 mb-4">
                            <h5 class=" text-info f-w-600 ml-3" style="font-size: 14px;">{{ $t('reuse.autoCheck') }}</h5>
                            <div class="col-sm-8">
                                <label for="selectedBkgId" class="col-form-label pt-0 f-w-600" style="font-size:16px;">1. {{ $t("reuse.bkgId") }}</label>  
                                <!-- <v-select label="selectedBkgId" class="style-chooser" v-model="selectedBkgId" :options="bkgIdList" :placeholder="$t('reuse.bkgIdPh')"/> -->
                                <v-select label="selectedBkgId" class="style-chooser" style="font-size:16px;" :value="selectedBkgId" @input="getContListByAgentGroup" :options="bkgIdList" :placeholder="$t('reuse.bkgIdPh')">                            
                                    <template #no-options>
                                            {{$t('reuse.noMatchingOptions')}}
                                    </template>
                                </v-select>
                            </div>
                        </div>                           
                        <div class="row ml-1 mb-4">
                            <div class="col-sm-6">
                                <label for="selectedCont" class="col-form-label pt-0 f-w-600" style="font-size:16px;">2. {{ $t("reuse.contNo") }}</label>  
                                <!-- <v-select label="selectedCont" class="style-chooser" v-model="selectedCont" :options="contList" :placeholder="$t('reuse.contNoPh')"/> -->
                                <v-select label="selectedCont" class="style-chooser" style="font-size:16px;" :value="selectedCont" @input="getSealNoListByAgentGroup" :options="contList" :placeholder="$t('reuse.contNoPh')">   
                                    <template #no-options>
                                        {{$t('reuse.noMatchingOptions')}}
                                    </template>
                                </v-select>
                            </div>                             
                            <div class="col-sm-6">
                                <label for="selectedSealNo" class="col-form-label pt-0 f-w-600" style="font-size:16px;">3. {{ $t("reuse.sealNo") }}</label>  
                                <v-select label="selectedSealNo" class="style-chooser" style="font-size:16px;" v-model="selectedSealNo" :options="sealNoList" :placeholder="$t('reuse.sealNoPh')">    
                                    <template #no-options>
                                        {{$t('reuse.noMatchingOptions')}}
                                    </template>
                                </v-select>
                                <!-- <v-select label="selectedSealNo" class="style-chooser"  :value="selectedSealNo" @input="getSealNoListByAgentGroup" :options="sealNoList" :placeholder="$t('reuse.sealNoPh')"/>                                    -->
                            </div>                            
                        </div>   
                      
                        <div class="form-group ml-3 my-5 text-primary f-w-600">
                            <div class="row">
                                <label for="contRueAddress" class="col-form-label pt-0 ml-3">{{ $t("reuse.locReu") }}</label>    
                                <p class=" ml-2 fw-bold text-danger"  > {{ $t("reuse.ifChg") }}</p>
                                <a href="#autocompleteReu"><u class=" ml-2 fw-bold text-info" style="font-size:15px" @click="showAC"> <i class="icofont icofont-map-search mr-2 " style="font-size:25px"></i>Google Map </u></a>                                
                            </div>
                            <input v-model="contReuAddress" class="form-control text-info" type="text" id="contReuAddress" :placeholder="$t('reuse.locReuPh') " >
                            <h6 class=" ml-3 text-info"> Latitude : {{ locReu.lat }}  ,  Longitude : {{ locReu.lng }}</h6>
                        </div> 

                        <div class="form-group mt-3 my-4 mb-0">
                            <button class="btn btn-primary btn-block" style="font-size:20px" type="button" @click="handleProcess">{{ $t('reuse.submit') }}</button>
                        </div>
                    </form>
                </div>
                <div class="col-sm-6" >
                    <section class="ui one column centered grid" v-show="showAutoComplete" style="position:relative; z-index: 5;">
                        <div class="column" >
                            <form class="ui left segment small form" >
                                <div class="ui message red" v-show="error">{{ error }}</div>
                                <div class="field" >
                                    <div class="row mb-3 col-sm-12">
                                        <div class=" col-sm-1">
                                            <i class="icon times text-primary" style="font-size: 24px;"  @click="offAC" ></i>                                                    
                                        </div>
                                        <div class="col-sm-11">
                                            <span class="text-warning"> {{ $t('company.donotEnterKey') }}</span>       
                                        </div>                                
                                    </div>
                                    
                                    <!-- <div class="ui message blue" >{{ compAddress }}</div> -->
                                    <div class="ui right icon input large" :class="{loading:spinner}">
                                        <input 
                                            type="text"
                                            id="autocompleteReu"
                                            v-model="address"
                                            onkeypress= "return event.keyCode != 13;"
                                        >
                                        <i class="dot circle link icon" @click="locatorButtonPressed" ></i>
                                    </div>
                                    <!-- dummy input to fix bug : only one input, make reload if we press ENTER.  -->
                                    <!-- <div class="ui message red" v-show="false">
                                        <input 
                                            type="text" 
                                        >
                                    </div> -->
                                </div>
                                <div class="row" @click="clearAddress">
                                    <i class="icon eraser f-10 ml-4 text-danger"  style="font-size: 18px;"></i>
                                    <h5 class="ml3 text-info" > {{ $t('company.clearAddress') }} </h5>
                                    
                                </div>
                            </form>                               
                        </div>
                    </section> 
                    <section id="map" style="background-color: lightblue;"></section>   
                </div>
            </div>
            <div>
                <h4 class="mb-1 mt-4 f-w-600">{{ $t('reuse.activeReuseList') }}</h4>
                <b-table
                    sticky-header
                    head-variant="light"
                    table-variant="success"
                    show-empty
                    sort-icon-left
                    stacked="md"      
                    :items="items"
                    :fields="fields"
                    :current-page="currentPage"
                    :per-page="perPage"
                >
                    <template #cell(act)="row" >
                        <b-button  size="sm" @click="actCancel(row.item)" class="m-1">
                            {{ $t('matchTx.cancel') }}
                        </b-button>
                    </template>
                    <template #cell(contImage)="row" >
                        <b-button  size="sm" @click="openModalImage(row.item, row.index, $event.target )" class="m-1">
                            {{ row.item.contNo }}
                        </b-button>
                    </template>
                    <template #empty>
                        <h5>{{ $t('reuse.noRecord') }}</h5>
                    </template>
                </b-table>
                <b-row>
                    <div class="row col-sm-4 ">
                    <b-col class="my-1 ml-3">
                        <b-pagination
                        v-model="currentPage"
                        :total-rows="totalRows"
                        :per-page="perPage"
                        align="fill"
                        class="my-0"
                        ></b-pagination>
                    </b-col>   
                    <b-col sm="3" md="6" class="my-1">
                        <b-form-group
                        :label= "$t('require.perPage')"
                        label-for="per-page-select"
                        label-cols-sm="6"
                        label-cols-md="4"
                        label-cols-lg="3"
                        label-align-sm="right"
                        label-size="sm"
                        class="mb-0"
                        >
                        <b-form-select
                            id="per-page-select"
                            v-model="perPage"
                            :options="pageOptions"
                        ></b-form-select>
                        </b-form-group>
                    </b-col>
                    </div>
                </b-row>
                    <!-- Info modal Container Image-->
                <b-modal id="contModal" :title="modalTitle" ok-only @hide="resetInfoModal">
                    <div class="card me-5" style=" width:15rem">
                        <img  class="card-img-top" :src="imageUriModal" alt="Image Preview"/>
                    </div>
                </b-modal>
            </div>
        </div>
        <!-- Container-fluid Ends-->
    </div>
</template>
<script>

const axios = require("axios");
import {googleMapApiKey, bkgIdList, updateActive, updateStatus, updateMatchTx2StepN, mTxIdById ,compNameLocalByCompId, postedByCompId, updateContActivebyContNo, contList, contAgent,sealNoList, sealNoListAgentGroup, contListAgentGroup ,agentAtContNo, locByCompId, saveWithId, agentAtBkgId} from "../../firebase"
export default {
	data(){
		return{
            showAutoComplete : false,
            bkgIdList : [],
            contList : [],
            sealNoList : [],
            address : '',
            error : '',        
            spinner: false,
            format: "yyyy-MM-dd",
            selectedBkgId : '',
            selectedCont : '',
            selectedSealNo : '',
            contReuAddress: '',
            locReu : '',
            userId: '',
            compId: '',
            // Reuse List
            items: [],
            fields: [
                { key: 'reuId', label: this.$t('require.reqrId'), sortable: true, },  
                { key: 'bkgId', label: this.$t('require.bkgId'), sortable: true, }, 
                { key: 'agent', label: this.$t('require.agentByBkgId'), sortable: true, },                    
                { key: 'contImage', label: this.$t('container.contNo'), variant: 'info'}, 
                { key: 'detDate', label: this.$t('container.detDate'), sortable: true, },                 
                { key: 'sealNo', label: this.$t('seal.sealNo'), sortable: true,},
                { key: 'contType', label: this.$t('require.contType'), sortable: true, },
                { key: 'contSize', label: this.$t('require.contSize'), sortable: true, },
                { key: 'contGrade', label: this.$t('require.contGrade'), sortable: true, },
                { key: 'compNameLocalReu', label: this.$t('reuse.compNameLocalReu'), sortable: true, },                
                { key: 'contReuAddress', label: this.$t('matchTx.contReqrAddress'), sortable: false, },              
                { key: 'timestamp', label: this.$t('shipApproval.date'), sortable: true, 
                    formatter: value => {
                        return this.$moment(value).format("YY-MM-DD @ HH:mm")
                }},
                { key: 'act', label: this.$t('matchTx.actions'), variant: 'danger'}
            ],
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
            imageUriModal: '',
            modalTitle : ''
		}
	},
	components: {
	},
	mounted(){
        let compId= localStorage.getItem("compId")
        let userId= localStorage.getItem("userId")
        this.compId = compId
        this.userId= userId        
        bkgIdList((res)=>{
            this.bkgIdList = res
        })
        // contList(compId , (res)=>{
        //     this.contList = res
        // })
        // sealNoList(compId, (res)=>{
        //     this.sealNoList = res
        // })
        postedByCompId( "Reuse",this.compId, "Matched" , (res)=>{
            this.items= res
            this.totalRows = res.length
        })

        // console.log("Require: compId =>"+compId)
        locByCompId(compId ,(res)=>{
            console.log("res =>"+ JSON.stringify(res , null , 2))
            this.locReu = {
                    lat : res.compLoc.lat,
                    lng : res.compLoc.lng
                }
            this.contReuAddress = res.compAddress
            this.showUserLocationOnTheMap(res.compLoc.lat, res.compLoc.lng )
        })

        var autocomplete = new google.maps.places.Autocomplete(
            document.getElementById('autocompleteReu'),
            {
                bounds: new google.maps.LatLngBounds(
                    new google.maps.LatLng(13.555 , 100.50)
                )
            }
        )
        autocomplete.addListener("place_changed",  ()=>{
            let place = autocomplete.getPlace()
            // console.log("place =>"+ JSON.stringify(place , null , 2))
            let lat = place.geometry.location.lat()
            let lng = place.geometry.location.lng() 
            if (!place.geometry) {
                this.$toasted.show(this.$t("require.fillinMatchedChar"), {theme: 'bubble',   position: 'bottom-right',   type: 'error', duration: 15000});
            } else {
                this.showUserLocationOnTheMap(lat, lng)
                this.getAddressFrom(lat, lng)              
                this.locReu = {
                    lat : lat,
                    lng : lng
                }
            }
        })        
	},
	methods:{
        async getContListByAgentGroup( value){
            if (value) {
                this.selectedBkgId = value
                await agentAtBkgId(value, async(agent)=>{
                    await contListAgentGroup(this.compId, agent, (res)=>{
                    this.contList = res
                    })
                })
            } else {
                this.$toasted.show(this.$t('reuse.addSelectedBkgId'), {theme: 'bubble', type: 'error' , position: 'bottom-center', duration: 5000}); 
            }
        },
        async getSealNoListByAgentGroup( value){
            if (value) {
                this.selectedCont = value
                await contAgent(value, async(agent)=>{
                    await sealNoListAgentGroup(this.compId, agent, (res)=>{
                    this.sealNoList = res
                    })
                })
            } else {
                this.$toasted.show(this.$t('reuse.addSelectedCont'), {theme: 'bubble', type: 'error' , position: 'bottom-center', duration: 5000}); 
            }
        },
        locatorButtonPressed(){
            this.spinner =true
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    (position) =>{ 
                        let lat = position.coords.latitude
                        let lng = position.coords.longitude
                        this.getAddressFrom(lat, lng )
                        this.showUserLocationOnTheMap(lat, lng )
                        this.locReu = {
                            lat : lat,
                            lng : lng
                        }
                    },
                    error => {
                        this.error = "Locator is unable to find the address. Please type your address manually."
                        this.spinner =false
                    }
                );
            } else {
                this.error = error.message
                this.spinner =false
                // console.log(`Your browser does not support geolocation API. Please enter your address`);
            }
        },
        async getAddressFrom(lat, lng){
            await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${googleMapApiKey}`)
            .then(response =>{
                if (response.data.error_message) {
                    this.error = response.data.error_message
                } else {
                   this.contReuAddress = response.data.results[0].formatted_address 
                    this.address = response.data.results[0].formatted_address
                    // this.address = ''
                }
                this.spinner =false
            })
            .catch(error =>{
                this.error = error.message
                this.spinner =false
                // console.log(`error.message =>${JSON.stringify(error)}`)
            })
        },
        showUserLocationOnTheMap(latitude , longitude){
            // Creat a map object
            let map = new google.maps.Map(document.getElementById("map"),{
                zoom:15,
                center: new google.maps.LatLng(latitude, longitude),
                mapTypeId: google.maps.MapTypeId.ROADMAP
            })
            // Add marker
            new google.maps.Marker({
                position: new google.maps.LatLng(latitude, longitude),
                map: map
            })
            // await new google.maps.marker.AdvanceedMarkerElement({
            //     position: await new google.maps.LatLng(latitude, longitude),
            //     map: map
            // })
        },
        clearAddress(){
            this.address = ''
        },
        showAC(){
            if (this.showAutoComplete == false) {
                this.showAutoComplete = true
            } else {
                this.showAutoComplete = false
            }
        },
        offAC(){
            if (this.showAutoComplete == true) {
                this.showAutoComplete = false
            } 
        },
        handleProcess(){
            let msg = 'completed'
            if (this.selectedBkgId ==='') {
                msg = 'reuse.addSelectedBkgId'
            } else if (this.selectedCont ===''){
                msg = 'reuse.addSelectedCont'
            } else if (this.selectedSealNo ===''){
                msg = 'reuse.addSelectedSealNo'
            } else if (this.contReuAddress ===''){
                msg = 'reuse.addContReuAddress'
            } else if (this.LocReqr== ''){
                msg = 'reuse.useGoogleMap'
            }  
            if (msg === 'completed'){
                agentAtContNo(this.selectedCont , async(res)=>{
                    let YYMM = this.$moment().format("YYMM")
                    let reuId = YYMM+'RU'+`${Math.floor(Math.random() * (90000 - 10000 + 1) + 10000)}`
                    let compNameLocalReu = await compNameLocalByCompId(this.compId)
                    let payload = {
                        reuId : reuId,
                        bkgId : this.selectedBkgId,
                        contNo : this.selectedCont,
                        sealNo : this.selectedSealNo,
                        contReuAddress: this.contReuAddress,
                        locReu: this.locReu,            
                        compId : this.compId,
                        compNameLocalReu : compNameLocalReu,
                        userId : this.userId,
                        status: "Matched",
                        timestamp: Date.parse(new Date()),
                        // detDate : res.detDate,
                        // detTS: res.detTS,
                        // contType : res.contType,
                        // contSize : res.contSize,
                        // contGrade : res.contGrade,
                        // imageUri : res.imageUri,
                        // maxGross : res.maxGross,
                        // tare : res.tare,
                        // agent : res.agent,                        
                        ...res
                    }
                    // console.log ('payload=>'+JSON.stringify(payload , null, 2))
                    await saveWithId('Reuse', reuId, payload).then(async()=>{
                        await this.saveMatchTx(payload)
                        await updateContActivebyContNo(this.selectedCont , false)  // exhuast the used container out 
                        await updateActive("Seal", this.selectedSealNo, false) // exhuast the used seal out 
                        this.resetReuseInputForm()
                        await this.refresh().then(()=>{
                            this.$toasted.show(this.$t('reuse.successSave'), {theme: 'bubble', type: 'success' , position: 'bottom-center', duration: 10000});    
                            window.location.reload()
                        })
                    })
                })                                  
            }else{
                this.$toasted.show(this.$t(msg), {theme: 'bubble', type: 'error' , position: 'bottom-center', duration: 5000});     
            }
        },

        async saveMatchTx( payload){
            let YYMM = this.$moment().format("YYMM")
            let mTxId = YYMM+'MT'+`${Math.floor(Math.random() * (90000 - 10000 + 1) + 10000)}`
            const data = {
                mTxId : mTxId,
                contNo : payload.contNo,
                distance: 0,
                bkgId: payload.bkgId, 
                compNameLocalRels: payload.compNameLocalReu,           
                contRelsAddress:  payload.contReuAddress,
                compNameLocalReqr :  payload.compNameLocalReu,             
                contReqrAddress: payload.contReuAddress,                
                jobTypeRels: 'Internal',
                jobTypeReqr: 'Internal',
                msReqr: 'matched',
                msReqrTS: Date.parse(new Date()),
                jbReqr:'waitApproval',
                jbReqrTS:Date.parse(new Date()),
                actReqr:{
                    cancel:false, 
                    accept: false, 
                    reject:false, 
                    attachSeal:false
                },  
                actRels:{
                    cancel:false, 
                    accept: false, 
                    reject:false, 
                    attachSeal:false
                },
                stepNo: 2,                                               
                msRels: 'matched',
                msRelsTS:Date.parse(new Date()),
                jbRels: 'waitApproval',
                jbRelsTS:Date.parse(new Date()),
                compIdRels: payload.compId,
                compIdReqr : payload.compId,
                userIdRels: payload.userId,
                userIdReqr: payload.userId,
                relsTS: Date.parse(new Date()),
                reqrTS: Date.parse(new Date()),
                relsId: payload.reuId,                
                reqrId: payload.reuId,
                status: "matched", 
                txSearcher: "Reuse", 
                timestamp: Date.parse(new Date()),
                active: true,                   
                sealNo : payload.sealNo,
                detDate : payload.detDate,
                detTS: payload.detTS,
                contType : payload.contType,
                contSize : payload.contSize,
                contGrade : payload.contGrade,
                imageUri : payload.imageUri,
                maxGross : payload.maxGross,
                tare : payload.tare,
                agent : payload.agent
            }
            await saveWithId('MatchTx', mTxId, data).then(async()=>{
            })
        },
        actCancel(item) {
            this.$bvModal.msgBoxConfirm(this.$t('matchTx.confirmCancelMsg'), {
            title: this.$t('matchTx.plsConfirm'),
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'warning',
            okTitle: this.$t('matchTx.yes'),
            cancelTitle: this.$t('matchTx.no'),
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true
            })
            .then(async (value) => {
                if (value) {
                    await mTxIdById( item.reuId, (mTxId)=>{    // reuId = reqrId for Reuse
                       updateMatchTx2StepN('MatchTx', mTxId , 1.1) 
                    }).then(async()=>{
                        updateStatus('Reuse', item.reuId, 'Cancel')// return  status of Poster for recycle.
                        updateContActivebyContNo( item.contNo , true )
                        updateActive('Seal', item.sealNo, true )
                        this.$toasted.show( this.$t('reuse.cancelled'), {theme: 'bubble', type: 'error' , position: 'bottom-center', duration: 5000});  
                        await this.refresh().then(()=>{
                            window.location.reload()
                        })                        
                    })

                    
                } else {
                return
                }
            })
            .catch(error => {
                this.$toasted.show( `Action-Delete: ${error}`, {theme: 'bubble', type: 'error' , position: 'bottom-center', duration: 5000});  
            })
        },  
        async refresh(){
            await postedByCompId( "Reuse",this.compId, "Matched" , (res)=>{
                this.items= res
                this.totalRows = res.length
            })
            this.contList = []
            this.sealList = []
            // await bkgIdList((res)=>{
            //     this.bkgIdList = res
            // })
            // await contList(this.compId , (res)=>{
            //     this.contList = res
            //     console.log(`contList => ${JSON.stringify(res , null , 2)}`)
            // })
            // await sealNoList(this.compId, (res)=>{
            //     this.sealNoList = res
            // })
        },
        resetReuseInputForm(){
            this.selectedBkgId = ''
            this.selectedCont = ''
            this.selectedSealNo = ''
        },
        openModalImage(item, index, button) {
            this.imageUriModal = item.imageUri
            this.modalTitle = item.contNo
            this.$root.$emit('bv::show::modal', "contModal" , button)
        },
        resetInfoModal() {
            this.modalTitle = ''
            this.imageUriModal = ''
        },
    }
}
</script>
<style>
.ui.button,
.dot.circle.icon{
    background-color: #138fc0;
    color : white
}
.pac-icon {
    display: none;
}
.pac-item{
    padding:0px;
    font-size: 10px;
    cursor: pointer;
}
.pac-item:hover {
    background-color: #ececec;
}
.pac-item-query {
    font-size: 10px;
}
#map {
    position:absolute;
    top:0;
    right:0;
    bottom: 0;
    left: 0;
    background: blue;
}

.style-chooser .vs__search::placeholder,
.style-chooser .vs__dropdown-toggle,
.style-chooser .vs__dropdown-menu {
  background: #dfe5fb;
  border: none;
  color: #394066;
  /* text-transform: lowercase;
  font-variant: small-caps; */
}

.style-chooser .vs__clear,
.style-chooser .vs__open-indicator {
  fill: #394066;
}


</style>

<template>
  <div>
    <Breadcrumbs main="Widgets" title="Chart"/>
    <!-- Container-fluid starts-->
    <div class="container-fluid">
      <div>
          <div class="bar-chart-widget">
            <div class="card">
              <div class="card-header">
                <h5>{{ $t('chart.sumReport') }} </h5>
              </div>
              <div class="col-sm-4 mx-3 mt-4">
                <b-button class="my-2 bg-warning f-w-600" style="font-size:20px;" variant="outline-primary" block @click="updateChart">
                 {{ $t('chart.monthly') }}
                </b-button>      
              </div> 
              <div class="card-body">
                <div class="chart-container">
                <apexchart type="bar" height="350" :options="chartOptions" :series="series"></apexchart>
                </div>
              </div>
            </div>
          </div>
      </div>
</div>
<!-- Container-fluid Ends-->
</div>
</template>
<script>
import { chartDataType} from "../../firebase"
import {frequencies , getMin , getMax , getRangeXaxis , getRangeXyyyyMM} from "../../utilities/util"
export default {
  data() {
    return {
      compId : '',
      lastYearTS : Date.parse(new Date()) - 31536000000 ,
      completedData : [],
      chartOptions: {
        chart: {
          type: 'bar',
          height: 350
        },
        subtitle: {
          text: this.$t('chart.monthlySum'),
          align: 'left',
          margin: 10,
          offsetX: 0,
          offsetY: 0,
          floating: false,
          style: {
            fontSize:  '20px',
            fontWeight:  'normal',
            fontFamily:  undefined,
            color:  '#9699a2'
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded'
          },
        },
        dataLabels: {
          enabled: true
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },
        xaxis: {
          categories: [],
        },
        yaxis: {
          title: {
            text: this.$t('chart.units')
          }
        },
        fill: {
          opacity: 1
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return  val
            }
          }
        }
      },   
      series: [{
          name: this.$t('chart.matching'),
          data: []
        }, 
        {
          name: this.$t('chart.reuse'),
          data: []
        }
      ]
    }
  },
  components: {
  },
  mounted(){
    this.compId= localStorage.getItem("compId")
    // console.log(`this.data(before)=> ${JSON.stringify(this.data, null , 2)}`)
    chartDataType(this.compId, "compIdReqr", ["Require", "Release"], this.lastYearTS, (recvr)=>{
      chartDataType(this.compId, "compIdRels",["Require", "Release"] , this.lastYearTS, (propsr)=>{
        let itemsRP = recvr.concat(propsr)
        let freqRP = itemsRP.length > 0 ? frequencies(itemsRP) : []
        // console.log(`freqRP => ${JSON.stringify(freqRP)}`)
        chartDataType(this.compId, "compIdReqr",["Reuse"], this.lastYearTS, (intnl)=>{
          let freqIntnl = intnl.length > 0 ? frequencies(intnl) : []
          // console.log(`freqIntnl => ${JSON.stringify(freqIntnl)}`)
          let itemsRPI = itemsRP.concat(intnl) 
          chartDataType(this.compId, "compIdReqr",["Transfer"], this.lastYearTS, (trn)=>{
            let freqTransfer = trn.length > 0 ? frequencies(trn) : []
            let itemsRPIT = itemsRPI.concat(trn)
            let minX = getMin(itemsRPIT).toString()
            let maxX = getMax(itemsRPIT).toString()
            let xaxis = getRangeXaxis(minX , maxX)
            let yyyyMM = getRangeXyyyyMM(minX , maxX)
            console.log(`yyyyMM => ${JSON.stringify(yyyyMM)}`)
            let matchData = []
            for (let i = 0; i < yyyyMM.length; i++) {
              if (freqRP[yyyyMM[i]]) {
                matchData.push(freqRP[yyyyMM[i]])
              } else {
                matchData.push(0)
              }
            }
            let reuseData = []
            for (let i = 0; i < yyyyMM.length; i++) {
              if (freqIntnl[yyyyMM[i]]) {
                reuseData.push(freqIntnl[yyyyMM[i]])
              } else {
                reuseData.push(0)
              }
            }
            let transferData = []
            for (let i = 0; i < yyyyMM.length; i++) {
              if (freqTransfer[yyyyMM[i]]) {
                transferData.push(freqTransfer[yyyyMM[i]])
              } else {
                transferData.push(0)
              }
            }
            this.chartOptions = {
              chart: {
                type: 'bar',
                height: 350
              },
              plotOptions: {
                bar: {
                  horizontal: false,
                  columnWidth: '55%',
                  endingShape: 'rounded'
                },
              },
              dataLabels: {
                enabled: true
              },
              stroke: {
                show: true,
                width: 2,
                colors: ['transparent']
              },
              xaxis: {
                categories: xaxis,
              },
              yaxis: {
                title: {
                  text: this.$t('chart.units')
                }
              },
              fill: {
                opacity: 1
              },
              tooltip: {
                y: {
                  formatter: function (val) {
                    return  val
                  }
                }
              }
            },   
            this.series = [{
                name: this.$t('chart.matching'),
                data: matchData
              }, 
              {
                name: this.$t('chart.reuse'),
                data: reuseData
              },
              {
                name: this.$t('chart.transfer'),
                data: transferData
              }
            ]                
          })
        })
      }) 
    })
  },
  methods:{
    updateChart(){
      chartDataType(this.compId, "compIdReqr", ["Require", "Release"], this.lastYearTS, (recvr)=>{
        chartDataType(this.compId, "compIdRels",["Require", "Release"] , this.lastYearTS, (propsr)=>{
          let itemsRP = recvr.concat(propsr)
          let freqRP = itemsRP.length > 0 ? frequencies(itemsRP) : []
          // console.log(`freqRP => ${JSON.stringify(freqRP)}`)
          chartDataType(this.compId, "compIdReqr",["Reuse"], this.lastYearTS, (intnl)=>{
            let freqIntnl = intnl.length > 0 ? frequencies(intnl) : []
            // console.log(`freqIntnl => ${JSON.stringify(freqIntnl)}`)
            let itemsRPI = itemsRP.concat(intnl) 
            chartDataType(this.compId, "compIdReqr",["Transfer"], this.lastYearTS, (trn)=>{
              let freqTransfer = trn.length > 0 ? frequencies(trn) : []
              let itemsRPIT = itemsRPI.concat(trn)
              let minX = getMin(itemsRPIT).toString()
              let maxX = getMax(itemsRPIT).toString()
              let xaxis = getRangeXaxis(minX , maxX)
              let yyyyMM = getRangeXyyyyMM(minX , maxX)
              console.log(`yyyyMM => ${JSON.stringify(yyyyMM)}`)
              let matchData = []
              for (let i = 0; i < yyyyMM.length; i++) {
                if (freqRP[yyyyMM[i]]) {
                  matchData.push(freqRP[yyyyMM[i]])
                } else {
                  matchData.push(0)
                }
              }
              let reuseData = []
              for (let i = 0; i < yyyyMM.length; i++) {
                if (freqIntnl[yyyyMM[i]]) {
                  reuseData.push(freqIntnl[yyyyMM[i]])
                } else {
                  reuseData.push(0)
                }
              }
              let transferData = []
              for (let i = 0; i < yyyyMM.length; i++) {
                if (freqTransfer[yyyyMM[i]]) {
                  transferData.push(freqTransfer[yyyyMM[i]])
                } else {
                  transferData.push(0)
                }
              }
              this.chartOptions = {
                chart: {
                  type: 'bar',
                  height: 350
                },
                plotOptions: {
                  bar: {
                    horizontal: false,
                    columnWidth: '55%',
                    endingShape: 'rounded'
                  },
                },
                dataLabels: {
                  enabled: true
                },
                stroke: {
                  show: true,
                  width: 2,
                  colors: ['transparent']
                },
                xaxis: {
                  categories: xaxis,
                },
                yaxis: {
                  title: {
                    text: this.$t('chart.units')
                  }
                },
                fill: {
                  opacity: 1
                },
                tooltip: {
                  y: {
                    formatter: function (val) {
                      return  val
                    }
                  }
                }
              },   
              this.series = [{
                  name: this.$t('chart.matching'),
                  data: matchData
                }, 
                {
                  name: this.$t('chart.reuse'),
                  data: reuseData
                },
                {
                  name: this.$t('chart.transfer'),
                  data: transferData
                }
              ]                
            })
          })
        }) 
      })
    }
  }
}
</script>
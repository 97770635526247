<template>
 <div class="page-wrapper">
    <div class="container-fluid p-0">
        <div class="authentication-main">
        <div class="row">

            <div class="col-md-12">
                <div class="auth-innerright">
                    <div class="authentication-box">
                        <div class="text-center"><img alt="" src="../assets/images/conex-logo.png" @dblclick="swRegister"></div>
                        <div class="card mt-4">
                            <div class="card-body">
                                 <div style="text-align: right">
                                    <LocaleSwitcher />  
                                </div>
                                <div class="text-center">
                                    <h4>{{ $t('loginPage.login') }}</h4>
   
                                    <h6 class="text-center text-info ">{{ $t('loginPage.enterEmailPwd') }} </h6>
                                </div>
                                <form class="theme-form">
                                <div class="form-group">
                                    <label class="col-form-label text-primary f-w-600 pt-0">{{ $t('loginPage.yourEmail') }}</label>
                                    <input v-model="email" class="form-control" type="email" :placeholder="$t('loginPage.enterEmailAddress')">
                                </div>
                                <div class="form-group">
                                    <label class="col-form-label  text-primary f-w-600">{{ $t('loginPage.pwd') }}</label>
                                    <input v-model="password" :type="type" class="form-control" name="login[password]" :placeholder="$t('loginPage.enterPwd')">
                                    <div class="show-hide"><span class="show" @click="showPassword"> </span></div>
                                </div>
                                <div  class="mt-0 mb-5" style="text-align: right" > 
                                    <span class="text-info text-underline mt-3"  @click="goForgotPassword"> {{ $t('loginPage.forgotPassword') }}</span>
                                </div>
                                <!-- <div class="checkbox p-0">
                                    <input id="checkbox1" type="checkbox">
                                    <label for="checkbox1">Remember me</label>
                                </div> -->
                                <div class="form-group form-row mt-3 mb-0">
                                    
                                        <button class="btn btn-primary btn-block" style="font-size: 18px;" type="button" @click="doLogin">{{ $t('loginPage.signIn') }}</button>
                                </div>
                                    <div class="form-group form-row mt-3 mb-0">
                                    <!-- <button class="btn btn-secondary btn-block" type="button" @click="">Login With
                                        Auth0
                                    </button> -->
                                    <button class="btn btn-secondary btn-block" style="font-size: 18px;" type="button"  v-show="registerBTN" @click="goRegister">{{ $t('loginPage.register') }}</button>
                                </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
    </div>
 </div>
</template>
<script>
import { signInWithEmailAndPassword } from "firebase/auth";
import {authUser, userInfoByUid} from "../firebase"
import LocaleSwitcher from "../components/localSwitcher.vue"
export default {
    components: { LocaleSwitcher },
    data() {
    return{ 
      type: 'password',
      email: '',
      password: '',
      registerBTN : false
    }
   },
   methods: {
        showPassword: function() {
            if (this.type === 'password') {
            this.type = 'text';
            } else {
            this.type = 'password';
            }
        },
        doLogin() {
            let msg = 'completed'
            let pattern = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g
            if (this.email ===''){
                msg = this.$t('loginPage.enterEmailMsg')
            } else if (!pattern.test(this.email)){
                msg = this.$t('loginPage.emailFormatMsg')
            } else if (this.password ===''){
                msg = this.$t('loginPage.enterPwdMsg')
            } else if (this.password.length < 8){
                msg = this.$t('loginPage.pwd8UpMsg')
            }  
            if (msg === 'completed'){
                signInWithEmailAndPassword(authUser, this.email, this.password)
                .then((userCredential) => {
                    if (userCredential.user) {
                        this.$toasted.show(this.$t('loginPage.successLogin') + this.email, {theme: 'bubble', type: 'success' , position: 'bottom-center', duration: 10000});
                        userInfoByUid(userCredential.user.uid, (res)=>{
                            console.log(`User info=> ${JSON.stringify(res , null ,2)}`)
                            localStorage.setItem('user', res.email)
                            localStorage.setItem('userId', res.userId)
                            localStorage.setItem('userCat', res.userCat)
                            localStorage.setItem('compId', res.compId)
                            switch (res.userCat) {
                                case 'agent':
                                    setTimeout(function () {
                                        window.location.href='/dashboard/shipApproval'  
                                    },500)                                         
                                    break;
                                case 'conex':
                                    setTimeout(function () {
                                        window.location.href='/dashboard/matchingAdmin'  
                                    },500)                                         
                                    break;
                                case 'cert':
                                    setTimeout(function () {
                                        window.location.href='/'  
                                    },500)                                         
                                    break;
                                case 'driver':
                                    setTimeout(function () {
                                        window.location.href='/'  
                                    },500)                                         
                                    break;
                                case 'logist':
                                    setTimeout(function () {
                                        window.location.href='/dashboard/matching'  
                                    },500)                                         
                                    break;    
                                default:
                                    break;
                            }                                  
                        })
                      
                        // setTimeout(function () {
                        //    window.location.href='/'  
                        // },500)
                        // this.$router.push('/');                                     
                    } else {
                        this.$toasted.show(this.$t('loginPage.failLogin') , {theme: 'bubble',   position: 'bottom-center',   type: 'error', duration: 5000});
                    }
                })
                .catch((error) => {
                    this.$toasted.show(`${this.$t('loginPage.failLogin')} : ${error.message}`, {theme: 'bubble',   position: 'bottom-center',   type: 'error', duration: 5000});
                });                                                 
            }else{
                this.$toasted.show(this.$t(msg), {theme: 'bubble', type: 'error' , position: 'bottom-center', duration: 5000});     
            }
        },
        goRegister() {
            this.$router.push('/auth/register');
        },
        swRegister() {
            if (this.registerBTN == false) {
                this.registerBTN = true;
            } else {
                this.registerBTN = false;
            }
        },
        goForgotPassword(){
            this.$router.push('/auth/forgotPassword');
        }

   }
}
</script>
<template>
	<div>
		<Breadcrumbs main="company.resource" title="company.header"/>
		<!-- Container-fluid starts-->
		<div class="container-fluid search-page">
			<div class="row">
                <div class="col-sm-6">
                    <form class="theme-form">
                        <div class="form-group">
                            <label for="compNameLocal" class="col-form-label pt-0">{{ $t("company.compNameLocal") }}</label>                                                 
                            <input  v-model="compNameLocal" class="form-control" type="text" id="compNameLocal" :placeholder="$t('company.compNameLocalPh')" >
                        </div>      
                        <div class="form-group">
                            <label for="compNameEng" class="col-form-label pt-0">{{ $t("company.compNameEng") }}</label>    
                            <input v-model="compNameEng" class="form-control" type="text" id="compNameEng" :placeholder="$t('company.compNameEngPh')" >
                        </div>
                        <div class="form-group">
                            <label for="busType" class="form-label">{{ $t("company.busType") }}</label>
                            <select class="form-control mb-1" id="busType" v-model="busType">
                                <option value="logist">{{ $t("company.logist") }}</option>
                                <option value="agent">{{ $t("company.agent") }}</option>
                                <option value="cert">{{ $t("company.cert") }}</option>    
                                <option value="conex">Conex</option>                                             
                            </select>
                        </div>
                        <div class="form-group text-primary">
                            <div class="row">
                                <label class="col-form-label pt-0 ml-3">{{ $t("company.compAddress") }}</label>    
                                <a href="#autocompleteComp"><u class=" ml-2 fw-bold text-info" style="font-size:15px" @click="showAC"> <i class="icofont icofont-map-search mr-2 " style="font-size:25px"></i>Google Map </u></a>                                
                            </div>

                            <input v-model="compAddress" class="form-control text-info" type="text" id="compAddress" :placeholder="$t('company.compAddressPh') " >
                            <h6 class=" ml-3 text-info"> Latitude : {{ compLoc.lat }}  ,  Longitude : {{ compLoc.lng }}</h6>
                        </div>
                        <div class="form-group">
                            <label for="contactEmail" class="col-form-label pt-0">{{ $t("company.contactEmail") }}</label>
                            <input v-model="contactEmail" class="form-control" type="email" id="contactEmail" :placeholder="$t('company.contactEmailPh')" >
                        </div>
                        <div class="form-group">
                             <label for="contactPhone" class="col-form-label pt-0">{{ $t("company.contactPhone") }}</label>
                            <input v-model="contactPhone" class="form-control" type="text" id="contactPhone" :placeholder="$t('company.contactPhonePh')">
                        </div>
                        <div class="form-group">
                            <label for="taxId" class="col-form-label pt-0">{{ $t("company.taxId") }}</label>                            
                            <input v-model="taxId" class="form-control" type="text" id="taxId" :placeholder="$t('company.taxIdPh')">
                        </div>
                        <div class="form-group mt-3 mb-0">
                            <button class="btn btn-primary btn-block" type="button" @click="handleSave">{{ $t('company.save') }}</button>
                        </div>
                    </form>
                </div>
                <div class="col-sm-6" >
                    <section class="ui one column centered grid" v-show="showAutoComplete" style="position:relative; z-index: 5;">
                        <div class="column" >
                            <form class="ui left segment small form" >
                                <div class="ui message red" v-show="error">{{ error }}</div>
                                <!-- <div class="ui segment" > -->
                                    <div class="field" >
                                        <div class="row mb-3 col-sm-12">
                                            <div class=" col-sm-1">
                                                <i class="icon times text-primary" style="font-size: 24px;"  @click="offAC" ></i>                                                    
                                            </div>
                                         
                                            <div class="col-sm-11">
                                                <span class="text-warning"> {{ $t('company.donotEnterKey') }}</span>       
                                            </div>                                    
                                        </div>
                                        <!-- <div class="ui message blue" >{{ compAddress }}</div> -->
                                        <div class="ui right icon input large" :class="{loading:spinner}">
                                            <input 
                                                type="text"
                                                id="autocompleteComp"
                                                v-model="address"
                                                onkeypress= "return event.keyCode != 13;"
                                            >
                                            <i class="dot circle link icon" @click="locatorButtonPressed" ></i>

                                        </div>
                                        
                                        <!-- dummy input to fix bug : only one input, make reload if we press ENTER.  -->
                                        <!-- <div class="ui message red" v-show="false">
                                            <input 
                                                type="text" 
                                            >
                                        </div> -->
                                    </div>

                                    <div class="row" @click="clearAddress">
                                        <i class="icon eraser f-10 ml-4 text-danger"  style="font-size: 18px;"></i>
                                        <h5 class="ml3 text-info" > {{ $t('company.clearAddress') }} </h5>
                                        
                                    </div>
                                <!-- </div> -->
                            </form>                               
                        </div>
                    </section> 
                    <section id="map" style="background-color: lightblue;"></section>   
                </div>
            </div>
        </div>
        <!-- Container-fluid Ends-->
    </div>
</template>
<script>

const axios = require("axios");
import {googleMapApiKey , saveWithId} from "../../firebase"

export default {
	data(){
		return{
            showAutoComplete : false,
            address : '',
            error : '',
            spinner: false,
            compId : '',
            compNameLocal: '',
            compNameEng: '',
            compAddress: '',
            compLoc: {},            
            contactEmail: '',
            contactPhone: '',
            taxId:'',
            busType: ''
            // dismissible : false,
            // showAlert: true,
            // danger : false
		}
	},
	components: {
		// VueGallery
	},
	mounted(){
        if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    (position) =>{ 
                            this.showUserLocationOnTheMap(position.coords.latitude, position.coords.longitude )
                        },
                    error => {
                        this.error = "Locator is unable to find the address. Please type your address manually."
                    }
                );
        } else {
            this.error = error.message
        }
        var autocomplete = new google.maps.places.Autocomplete(
            document.getElementById('autocompleteComp'),
            {
                bounds: new google.maps.LatLngBounds(
                    new google.maps.LatLng(13.555 , 100.50)
                )
            }
        )
        autocomplete.addListener("place_changed",  ()=>{
            let place = autocomplete.getPlace()
            // console.log("place =>"+ JSON.stringify(place , null , 2))
            let lat = place.geometry.location.lat()
            let lng = place.geometry.location.lng() 
            if (!place.geometry) {
                this.$toasted.show(this.$t("require.fillinMatchedChar"), {theme: 'bubble',   position: 'bottom-right',   type: 'error', duration: 15000});
            } else {
                this.showUserLocationOnTheMap(lat, lng)
                this.getAddressFrom(lat, lng)              
                this.compLoc = {
                    lat : lat,
                    lng : lng
                }
            }
        })        
	},
	methods:{
        locatorButtonPressed(){
            this.spinner =true
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    (position) =>{ 
                        let lat = position.coords.latitude
                        let lng = position.coords.longitude
                        this.getAddressFrom(lat, lng )
                        this.showUserLocationOnTheMap(lat, lng )
                        this.compLoc = {
                            lat : lat,
                            lng : lng
                        }
                    },
                    error => {
                        this.error = "Locator is unable to find the address. Please type your address manually."
                        this.spinner =false
                    }
                );
            } else {
                this.error = error.message
                this.spinner =false
                // console.log(`Your browser does not support geolocation API. Please enter your address`);
            }
        },
        async getAddressFrom(lat, lng){
            await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${googleMapApiKey}`)
            .then(response =>{
                if (response.data.error_message) {
                    this.error = response.data.error_message
                } else {
                   this.compAddress = response.data.results[0].formatted_address 
                    this.address = response.data.results[0].formatted_address
                    // this.address = ''
                }
                this.spinner =false
            })
            .catch(error =>{
                this.error = error.message
                this.spinner =false
                // console.log(`error.message =>${JSON.stringify(error)}`)
            })
        },
        showUserLocationOnTheMap(latitude , longitude){
            // Creat a map object
            let map = new google.maps.Map(document.getElementById("map"),{
                zoom:15,
                center: new google.maps.LatLng(latitude, longitude),
                mapTypeId: google.maps.MapTypeId.ROADMAP
            })
            // Add marker
            new google.maps.Marker({
                position: new google.maps.LatLng(latitude, longitude),
                map: map
            })
            // await new google.maps.marker.AdvanceedMarkerElement({
            //     position: await new google.maps.LatLng(latitude, longitude),
            //     map: map
            // })
        },
        clearAddress(){
            this.address = ''
        },
        showAC(){
            if (this.showAutoComplete == false) {
                this.showAutoComplete = true
            } else {
                this.showAutoComplete = false
            }
        },
        offAC(){
            if (this.showAutoComplete == true) {
                this.showAutoComplete = false
            } 
        },
        handleSave(){
            let msg = 'completed'
            let pattern = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g
            if (this.compNameLocal ==='') {
                msg = 'company.addCompNameLocal'
            } else if (this.compNameEng ===''){
                msg = 'company.addCompNameEng'
            } else if (this.busType ===''){
                msg = 'company.selBusType'
            } else if (this.compAddress ===''){
                msg = 'company.addCompAddress'
            } else if (this.compLoc.lat == null || this.compLoc.lat == undefined){
                msg = 'company.useGoogleMap'
            } else if (this.contactEmail ===''){
                msg = 'company.addContactEmail'
            } else if (!pattern.test(this.contactEmail)){
                msg = 'company.contactEmailFormat'
            } else if (this.contactPhone ===''){
                msg = 'company.addContactPhone'
            } else if (isNaN(parseInt(this.contactPhone))){
                msg = 'company.contactPhoneNumber'
            } else if (this.contactPhone.length < 9 ){
                msg = 'company.contactPhone9Digits'
            } else if (this.taxId ===''){
                msg = 'company.addTaxId'
            } else if (isNaN(parseInt(this.taxId))){
                msg = 'company.taxIdNumber'
            } else if (this.taxId.length != 13 ){
                msg = 'company.taxId13Digits'
            }  
            if (msg === 'completed'){
                // console.log ('msg=>'+msg)
                let compId = this.compNameEng.substring(0,1).toUpperCase()+`${Math.floor(Math.random() * (90000 - 10000 + 1) + 10000)}`
                let payload = {
                    compId : compId,
                    compNameLocal: this.compNameLocal,
                    compNameEng: this.compNameEng,
                    busType: this.busType,
                    compAddress: this.compAddress,
                    compLoc: this.compLoc,            
                    contactEmail: this.contactEmail,
                    contactPhone: this.contactPhone,
                    taxId:this.taxId,
                    active: true
                }
               // console.log ('payload=>'+JSON.stringify(payload , null, 2))
                saveWithId('Company', compId, payload).then (()=>{
                    this.refresh()
                    this.$toasted.show(this.$t('reuse.successSave'), {theme: 'bubble', type: 'success' , position: 'bottom-center', duration: 5000});  
                })                             
            }else{
                this.$toasted.show(this.$t(msg), {theme: 'bubble', type: 'error' , position: 'bottom-center', duration: 5000});     
            }
        },
        refresh(){
            this.compId = '',
            this.compNameLocal = '',
            this.compNameEng = '',
            this.compAddress = '',
            this.compLoc = {},            
            this.contactEmail = '',
            this.contactPhone = '',
            this.taxId ='',
            this.busType = ''
        }
    }
}
</script>
<style>
.ui.button,
.dot.circle.icon{
    background-color: #138fc0;
    color : white
}
.pac-icon {
    display: none;
}
.pac-item{
    padding:0px;
    font-size: 10px;
    cursor: pointer;
}
.pac-item:hover {
    background-color: #ececec;
}
.pac-item-query {
    font-size: 10px;
}
#map {
    position:absolute;
    top:0;
    right:0;
    bottom: 0;
    left: 0;
    background: blue;
}
</style>

<template>
  <div>
    <Breadcrumbs main="matchTx.dashboard" title="matchTx.header" />
    <b-container fluid>
      <!-- <h3 class="mb-1 mt-4 f-w-600">{{ $t('matchTx.header') }}</h3> -->
    <div>
      <b-row>
        <h4 class="mb-1 mt-1 ml-3 f-w-600">{{ $t('matchTx.reqrReceiver') }}</h4> 
        <b-row>
          <b-col lg="5" class="my-1">
            <b-form-group
              :label="$t('matchTx.sort')"
              label-for="sort-by-select-reqr"
              label-cols-sm="3"
              label-align-sm="right"
              label-size="lg"
              class="mb-0"
              v-slot="{ ariaDescribedbyReqr }"
            >
              <b-input-group size="lg">
                <b-form-select
                  id="sort-by-select-reqr"
                  v-model="sortByReqr"
                  :options="sortOptionsReqr"
                  :aria-describedby="ariaDescribedbyReqr"
                  class="w-70 text-info"
                >
                  <template #first>
                    <option value="">-- none --</option>
                  </template>
                </b-form-select>

                <b-form-select
                  v-model="sortDescReqr"
                  :disabled="!sortByReqr"
                  :aria-describedby="ariaDescribedbyReqr"
                  size="lg"
                  class="w-30 text-info"
                >
                  <option :value="false">{{$t('matchTx.asc')}}</option>
                  <option :value="true">{{$t('matchTx.desc')}}</option>
                </b-form-select>
              </b-input-group>
            </b-form-group>
          </b-col>

          <b-col lg="5" class="my-1">
            <b-form-group
              :label="$t('matchTx.initSort')"
              label-for="initial-sort-select-reqr"
              label-cols-sm="3"
              label-align-sm="right"
              label-size="lg"
              class="mb-0"
            >
              <b-form-select
                id="initial-sort-select-reqr"
                v-model="sortDirectionReqr"
                :options="['asc', 'desc', 'last']"
                size="lg"
                class="text-info"
              ></b-form-select>
            </b-form-group>
          </b-col>

          <b-col lg="5" class="my-1">
            <b-form-group
              :label="$t('matchTx.filter')"
              label-for="filter-input-reqr"
              label-cols-sm="3"
              label-align-sm="right"
              label-size="lg"
              class="mb-0"
            >
              <b-input-group size="sm">
                <b-form-input
                  id="filter-input-reqr"
                  v-model="filterReqr"
                  type="search"
                  :placeholder="$t('matchTx.typeToSearch')"
                  class="text-info"
                  style="font-size: 18px;"
                ></b-form-input>
                <b-input-group-append>
                  <b-button size="md" variant="primary" :disabled="!filterReqr" @click="filterReqr = ''">
                    {{ $t('matchTx.clear') }}
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>

          <b-col lg="5" class="my-1">
            <b-form-group
              v-model="sortDirectionReqr"
              :label="$t('matchTx.filterOn')"
              :description= "$t('matchTx.uncheck')"
              label-cols-sm="3"
              label-align-sm="right"
              label-size="lg"
              class="mb-0"
              v-slot="{ ariaDescribedbyReqr }"
            >
              <b-form-checkbox-group
                v-model="filterOnReqr"
                :aria-describedby="ariaDescribedbyReqr"
                class="mt-1 text-info"
                plain
              >
                <b-form-checkbox value="agent">{{ $t('matchTx.agent') }}</b-form-checkbox>
                <b-form-checkbox value="bkgId">{{ $t('matchTx.bkgId') }}</b-form-checkbox>
                <b-form-checkbox value="contNo">{{ $t('matchTx.contNo') }}</b-form-checkbox>     
                <b-form-checkbox value="contSize">{{ $t('matchTx.contSize') }}</b-form-checkbox>   
                <b-form-checkbox value="contType">{{ $t('matchTx.contType') }}</b-form-checkbox>   
                <b-form-checkbox value="contGrade">{{ $t('matchTx.contGrade') }}</b-form-checkbox>
                <b-form-checkbox value="sealNo">{{ $t('matchTx.sealNo') }}</b-form-checkbox>  
              </b-form-checkbox-group>
            </b-form-group>
          </b-col>
        </b-row>
        <div class=" mt-3 ">
          <b-dropdown id="dropdown-left" size="lg" :text=" $t('matchTx.exportData')" variant="info" class="m-2">
            <b-dropdown-text style="width: 300px;">
              {{ $t('matchTx.exportDataMsg') }}
            </b-dropdown-text>
            <b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-item class="m-2" style="font-size: 20px;"  @click="back7days">{{ $t('matchTx.back7days') }}</b-dropdown-item>
            <b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-item class="m-2" style="font-size: 20px;"  @click="back30days" >{{ $t('matchTx.back30days') }}</b-dropdown-item>
            <b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-item  class="m-2"  style="font-size: 20px;" @click="inYear">{{ $t('matchTx.inYear') }}</b-dropdown-item>
        </b-dropdown>
        </div>
      </b-row>
      <b-table
        sticky-header
        head-variant="light"
        table-variant="info"
        show-empty
        sort-icon-left
        stacked="md"
        :sort-by.sync="sortByReqr"
        :sort-desc.sync="sortDescReqr"
        :sort-direction="sortDirectionReqr"
        :filter="filterReqr"
        :filter-included-fields="filterOnReqr"
        :items="itemsReqr"
        :fields="fieldsReqr"
        :current-page="currentPageReqr"
        :per-page="perPageReqr"

        @filtered="onFilteredReqr"
      >
        <template #cell(contImageReqr)="row" >
          <b-button  size="md" @click="openModalImageReqr(row.item, row.index, $event.target )" class="m-1">
              {{ row.item.contNo }}
          </b-button>
        </template>
        <template #cell(actReqr)="row" >
          <b-button  v-show="row.item.actReqr.cancel" size="md" variant="danger" @click="actReqrCancel(row.item)" class="m-1 ">
            {{ $t('matchTx.cancel') }}
          </b-button>
          <b-button  v-show="row.item.actReqr.accept" size="md" variant="success" @click="actReqrAccept(row.item)" class="m-1">
            {{ $t('matchTx.accept') }}
          </b-button>
          <b-button  v-show="row.item.actReqr.reject" size="md" variant="danger" @click="actReqrReject(row.item)" class="m-1">
            {{ $t('matchTx.reject') }}
          </b-button>
          <b-button  v-show="row.item.actReqr.attachSeal" size="md" variant="info" @click="actReqrAttachSeal(row.item)" class="m-1">
            {{ $t('matchTx.attachSeal') }}
          </b-button>
          <b-button  v-show="row.item.actReqr.updateSeal" size="md" variant="warning" @click="actReqrUpdateSeal(row.item)" class="m-1">
            {{ $t('matchTx.updateSeal') }}
          </b-button>
          <b-button  v-show="row.item.actReqr.terminate" size="md" variant="danger" @click="actReqrTerminate(row.item)" class="m-1">
            {{ $t('matchTx.terminate') }}
          </b-button>
          <b-button  v-show="row.item.actReqr.transfer" size="md" variant="warning" @click="actReqrTransfer(row.item)" class="m-1">
            {{ $t('matchTx.transfer') }}
          </b-button>
        </template>
        <template #empty>
            <h5>{{ $t('reuse.noRecord') }}</h5>
        </template>
      </b-table>
      <b-row>
        <div class="row col-sm-4 ">
          <b-col class="my-1 ml-3">
            <b-pagination
              v-model="currentPageReqr"
              :total-rows="totalRowsReqr"
              :per-page="perPageReqr"
              align="fill"
              class="my-0"
            ></b-pagination>
          </b-col>   
          <b-col sm="3" md="6" class="my-1">
            <b-form-group
              :label= "$t('require.perPage')"
              label-for="per-page-select"
              label-cols-sm="6"
              label-cols-md="4"
              label-cols-lg="3"
              label-align-sm="right"
              label-size="sm"
              class="mb-0"
            >
              <b-form-select
                id="per-page-select"
                v-model="perPageReqr"
                :options="pageOptionsReqr"
              ></b-form-select>
            </b-form-group>
          </b-col>
        </div>
      </b-row>
      <!-- Info modal Reqr Attch Seal-->
      <div>
        <!-- <b-button @click="showSealNoModal=true" variant="primary">Show Modal</b-button> -->
        <b-modal
          :id="actReqrAttachSealModal.id" 
          :title="actReqrAttachSealModal.title"
          v-model="showSealNoModal"
          header-bg-variant="info"
          header-text-variant="dark"
          body-bg-variant="light"
          body-text-variant="dark"
          hide-footer
        >
          <b-row class="mb-1">
            <b-col cols="4">{{ $t('matchTx.selSealNo') }}</b-col>
            <b-col>
              <b-form-select
                v-model="selectedSealNo"
                :options="sealNos"
              ></b-form-select>
            </b-col>
          </b-row>
          <div class="w-100">
            <span class="float-left">{{$t('matchTx.sealNo')}} : </span> <span> {{ selectedSealNo }}</span> 
            <b-button
              variant="primary"
              size="sm"
              class="float-right"
              @click="clickAttachSeal_OK"
            >
              OK
            </b-button>
          </div>
        </b-modal>
        <b-modal
          :id="actReqrUpdateSealModal.id" 
          :title="actReqrUpdateSealModal.title"
          v-model="showUpdateSealNoModal"
          header-bg-variant="info"
          header-text-variant="dark"
          body-bg-variant="light"
          body-text-variant="dark"
          hide-footer
        >
          <b-row class="mb-1">
            <b-col cols="4">{{ $t('matchTx.selSealNo') }}</b-col>
            <b-col>
              <b-form-select
                v-model="selectedSealNo"
                :options="sealNos"
              ></b-form-select>
            </b-col>
          </b-row>
          <div class="w-100">
            <span class="float-left">{{$t('matchTx.sealNo')}} : </span> <span> {{ selectedSealNo }}</span> 
            <b-button
              variant="primary"
              size="sm"
              class="float-right"
              @click="clickUpdateSeal_OK"
            >
              OK
            </b-button>
          </div>
        </b-modal>
        <b-modal
          :id="actReqrTransferModal.id" 
          :title="actReqrTransferModal.title"
          v-model="showTransferModal"
          header-bg-variant="info"
          header-text-variant="dark"
          body-bg-variant="light"
          body-text-variant="dark"
          hide-footer
        >
          <b-row class="mb-1">
            <b-col cols="4">{{ $t('matchTx.selBkgId') }}</b-col>
            <b-col>
              <b-form-select
                v-model="selectedBkgId"
                :options="bkgIds"
              ></b-form-select>
            </b-col>
          </b-row>
          <div class="w-100">
            <span class="float-left">{{$t('matchTx.bkgId')}} : </span> <span> {{ selectedBkgId }}</span> 
            <b-button
              variant="primary"
              size="sm"
              class="float-right"
              @click="clickBkgId_OK"
            >
              OK
            </b-button>
          </div>
        </b-modal>
          <!-- Info modal Container Image Reqr-->
        <b-modal id="contModalReqr" :title="modalTitleReqr" ok-only @hide="resetInfoModalReqr">
          <div class="card me-5" style=" width:15rem">
              <img  class="card-img-top" :src="imageUriModalReqr" alt="Image Preview - Require"/>
          </div>
        </b-modal>
          <!-- Info modal Container Image Rels-->
        <b-modal id="contModalRels" :title="modalTitleRels" ok-only @hide="resetInfoModalRels">
          <div class="card me-5" style=" width:15rem">
              <img  class="card-img-top" :src="imageUriModalRels" alt="Image Preview - Release"/>
          </div>
        </b-modal>
      </div>

    </div>
    <div>
      <b-row>
        <h4 class="mb-1 mt-1 ml-3 f-w-600">{{ $t('matchTx.relsProposer') }}</h4> 
        <b-row>
          <b-col lg="6" class="my-1">
            <b-form-group
              :label="$t('matchTx.sort')"
              label-for="sort-by-select-rels"
              label-cols-sm="3"
              label-align-sm="right"
              label-size="lg"
              class="mb-0"
              v-slot="{ ariaDescribedbyRels }"
            >
              <b-input-group size="lg">
                <b-form-select
                  id="sort-by-select-rels"
                  v-model="sortByRels"
                  :options="sortOptionsRels"
                  :aria-describedby="ariaDescribedbyRels"
                  class="w-70 text-info"
                >
                  <template #first>
                    <option value="">-- none --</option>
                  </template>
                </b-form-select>

                <b-form-select
                  v-model="sortDescRels"
                  :disabled="!sortByRels"
                  :aria-describedby="ariaDescribedbyRels"
                  size="lg"
                  class="w-30 text-info"
                >
                  <option :value="false">{{$t('matchTx.asc')}}</option>
                  <option :value="true">{{$t('matchTx.desc')}}</option>
                </b-form-select>
              </b-input-group>
            </b-form-group>
          </b-col>

          <b-col lg="6" class="my-1">
            <b-form-group
              :label="$t('matchTx.initSort')"
              label-for="initial-sort-select-rels"
              label-cols-sm="3"
              label-align-sm="right"
              label-size="lg"
              class="mb-0"
            >
              <b-form-select
                id="initial-sort-select-rels"
                v-model="sortDirectionRels"
                :options="['asc', 'desc', 'last']"
                size="lg"
                class="text-info"
              ></b-form-select>
            </b-form-group>
          </b-col>

          <b-col lg="6" class="my-1">
            <b-form-group
              :label="$t('matchTx.filter')"
              label-for="filter-input-rels"
              label-cols-sm="3"
              label-align-sm="right"
              label-size="lg"
              class="mb-0"
            >
              <b-input-group size="sm">
                <b-form-input
                  id="filter-input-rels"
                  v-model="filterRels"
                  type="search"
                  :placeholder="$t('matchTx.typeToSearch')"
                  class="text-info"
                  style="font-size: 18px;"
                ></b-form-input>
                <b-input-group-append>
                  <b-button size="md" variant="primary" :disabled="!filterRels" @click="filterRels = ''">
                    {{ $t('matchTx.clear') }}
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>

          <b-col lg="6" class="my-1">
            <b-form-group
              v-model="sortDirectionRels"
              :label="$t('matchTx.filterOn')"
              :description= "$t('matchTx.uncheck')"
              label-cols-sm="3"
              label-align-sm="right"
              label-size="lg"
              class="mb-0"
              v-slot="{ ariaDescribedbyRels }"
            >
              <b-form-checkbox-group
                v-model="filterOnRels"
                :aria-describedby="ariaDescribedbyRels"
                class="mt-1 text-info"
                plain
              >
                <b-form-checkbox value="agent">{{ $t('matchTx.agent') }}</b-form-checkbox>
                <b-form-checkbox value="bkgId">{{ $t('matchTx.bkgId') }}</b-form-checkbox>
                <b-form-checkbox value="contNo">{{ $t('matchTx.contNo') }}</b-form-checkbox>     
                <b-form-checkbox value="contSize">{{ $t('matchTx.contSize') }}</b-form-checkbox>   
                <b-form-checkbox value="contType">{{ $t('matchTx.contType') }}</b-form-checkbox>   
                <b-form-checkbox value="contGrade">{{ $t('matchTx.contGrade') }}</b-form-checkbox>
              </b-form-checkbox-group>
            </b-form-group>
          </b-col>
        </b-row>
      </b-row>
      <b-table
        sticky-header
        head-variant="light"
        table-variant="success"
        show-empty
        sort-icon-left
        stacked="md"
        :sort-by.sync="sortByRels"
        :sort-desc.sync="sortDescRels"
        :sort-direction="sortDirectionRels"
        :filter="filterRels"
        :filter-included-fields="filterOnRels"
        :items="itemsRels"
        :fields="fieldsRels"
        :current-page="currentPageRels"
        :per-page="perPageRels"
        @filtered="onFilteredReqr"      
        >
        <template #cell(contImageRels)="row" >
          <b-button  size="md" @click="openModalImageRels(row.item, row.index, $event.target )" class="m-1">
              {{ row.item.contNo }}
          </b-button>
        </template>
        <template #cell(actRels)="row" >
          <b-button  v-show="row.item.actRels.cancel" size="md" variant="danger" @click="actReqrCancel(row.item)" class="m-1 ">
            {{ $t('matchTx.cancel') }}
          </b-button>
          <b-button  v-show="row.item.actRels.accept" size="md" variant="success" @click="actReqrAccept(row.item)" class="m-1">
            {{ $t('matchTx.accept') }}
          </b-button>
          <b-button  v-show="row.item.actRels.reject" size="md" variant="danger" @click="actReqrReject(row.item)" class="m-1">
            {{ $t('matchTx.reject') }}
          </b-button>
        </template>
        <template #empty>
            <h5>{{ $t('reuse.noRecord') }}</h5>
        </template>
      </b-table>
      <b-row>
        <div class="row col-sm-4 ">
          <b-col class="my-1 ml-3">
            <b-pagination
              v-model="currentPageRels"
              :total-rows="totalRowsRels"
              :per-page="perPageRels"
              align="fill"
              class="my-0"
            ></b-pagination>
          </b-col>   
          <b-col sm="3" md="6" class="my-1">
            <b-form-group
              :label="$t('require.perPage')"
              label-for="per-page-select"
              label-cols-sm="6"
              label-cols-md="4"
              label-cols-lg="3"
              label-align-sm="right"
              label-size="sm"
              class="mb-0 "
            > 
              <b-form-select
                id="per-page-select"
                v-model="perPageRels"
                :options="pageOptionsRels"
              ></b-form-select>
            </b-form-group>
          </b-col>          
        </div>
      </b-row>
    </div>
  </b-container>
  </div>
</template>
<script>

import {matchTxsRels,bkgIdListAgentGroup, makeMatchTxTrStep2, matchTxsReqr, updateStatus, updateActive, updateSealMatchTx, updateContActivebyContNo, sealNoListAgentGroup, updateMatchTx2StepN , xlsxRecvr, xlsxPropsr,xlsxInternal, xlsxTransfer } from "../../firebase"
import {exportXlsx} from "../../utilities/util" 
  export default {
    data() {
      return {
        compId : '',
        itemsReqr: [],
        itemsRels: [],
        showSealNoModal: false,
        showUpdateSealNoModal: false,
        sealNos: [],
        selectedSealNo: '',
        currentSealNo: '',
        showTransferModal: false,
        bkgIds: [],
        selectedBkgId: '',
        selected_mTxId : '',
        fieldsReqr: [
          { key: 'mTxId', label: this.$t('matchTx.mTxId'), sortable: true, },  
          { key: 'bkgId', label: this.$t('require.bkgId'), sortable: true, },     
          { key: 'contImageReqr', label: this.$t('matchTx.contNo'), sortable: true, variant: 'info'},  
          // { key: 'contNo', label: this.$t('matchTx.contNo'), sortable: true, },          
          { key: 'detDate', label: this.$t('matchTx.detDate'), sortable: true, }, 
          { key: 'contType', label: this.$t('matchTx.contType'), sortable: true, },
          { key: 'contSize', label: this.$t('matchTx.contSize'), sortable: true, },
          { key: 'contGrade', label: this.$t('matchTx.contGrade'), sortable: true, },
          { key: 'maxGross', label: this.$t('container.maxGross'), sortable: true, },
          { key: 'tare', label: this.$t('container.tare'), sortable: true, },
          { key: 'sealNo', label: this.$t('matchTx.sealNo'), sortable: true, },
          { key: 'agent', label: this.$t('matchTx.agent'), sortable: true, },                
          { key: 'compNameLocalRels', label: this.$t('matchTx.compNameLocalRels'), sortable: true, },                
          { key: 'contRelsAddress', label: this.$t('matchTx.contRelsAddress'), sortable: false, },
          { key: 'jobTypeRels', label: this.$t('matchTx.jobTypeRels'), sortable: true, 
              formatter: value => {
                return this.$t(`require.${value}`)
          }}, 
          { key: 'distance', label: this.$t('matchTx.distance'), sortable: true, },                
          { key: 'compNameLocalReqr', label: this.$t('matchTx.compNameLocalReqr'), sortable: true, },                
          { key: 'contReqrAddress', label: this.$t('matchTx.contReqrAddress'), sortable: false, },
          { key: 'jobTypeReqr', label: this.$t('matchTx.jobTypeReqr'), sortable: true, 
              formatter: value => {
                return this.$t(`require.${value}`)
          }},           
          { key: 'timestamp', label: this.$t('shipApproval.date'), sortable: true, 
                    formatter: value => {
                    return this.$moment(value).format("YY-MM-DD @ HH:mm")
          }},
          { key: 'msReqr', label: this.$t('matchTx.matchStatus'), sortable: false, variant: 'warning',
              formatter: value => {
                 return this.$t(`matchTx.${value}`)
              }}, 
          { key: 'jbReqr', label: this.$t('matchTx.jobStatus'), sortable: true, variant: 'warning',
              formatter: value => {
                return this.$t(`matchTx.${value}`)
              }}, 
          { key: 'actReqr', label: this.$t('matchTx.actions'), variant: 'warning' }
        ],
        fieldsRels: [
          { key: 'mTxId', label: this.$t('matchTx.mTxId'), sortable: true, },  
          { key: 'bkgId', label: this.$t('require.bkgId'), sortable: true, },     
          { key: 'contImageRels', label: this.$t('matchTx.contNo'),sortable: true, variant: 'info'},  
          // { key: 'contNo', label: this.$t('matchTx.contNo'), sortable: true, },          
          { key: 'detDate', label: this.$t('matchTx.detDate'), sortable: true, }, 
          { key: 'contType', label: this.$t('matchTx.contType'), sortable: true, },
          { key: 'contSize', label: this.$t('matchTx.contSize'), sortable: true, },
          { key: 'contGrade', label: this.$t('matchTx.contGrade'), sortable: true, },
          { key: 'maxGross', label: this.$t('container.maxGross'), sortable: true, },
          { key: 'tare', label: this.$t('container.tare'), sortable: true, },
          { key: 'agent', label: this.$t('matchTx.agent'), sortable: true, },                
          { key: 'compNameLocalRels', label: this.$t('matchTx.compNameLocalRels'), sortable: true, },                
          { key: 'contRelsAddress', label: this.$t('matchTx.contRelsAddress'), sortable: false, },
          { key: 'jobTypeRels', label: this.$t('matchTx.jobTypeRels'), sortable: true, 
            formatter: value => {
              return this.$t(`require.${value}`)
            }},   
          { key: 'distance', label: this.$t('matchTx.distance'), sortable: true, },                   
          { key: 'compNameLocalReqr', label: this.$t('matchTx.compNameLocalReqr'), sortable: true, },                
          { key: 'contReqrAddress', label: this.$t('matchTx.contReqrAddress'), sortable: false, },
          { key: 'jobTypeReqr', label: this.$t('matchTx.jobTypeReqr'), sortable: true, 
            formatter: value => {
              return this.$t(`require.${value}`)
          }},           
          { key: 'timestamp', label: this.$t('shipApproval.date'), sortable: true, 
            formatter: value => {
              return this.$moment(value).format("YY-MM-DD @ HH:mm")
          }},
          { key: 'msRels', label: this.$t('matchTx.matchStatus'), sortable: false,variant: 'warning',
              formatter: value => {
                return this.$t(`matchTx.${value}`)
              }}, 
          { key: 'jbRels', label: this.$t('matchTx.jobStatus'), sortable: true, variant: 'warning',
              formatter: value => {
                return this.$t(`matchTx.${value}`)
              }}, 
          { key: 'actRels', label: this.$t('matchTx.actions'), variant: 'warning'}
        ],
        totalRowsReqr: 1,
        currentPageReqr: 1,
        perPageReqr: 10,
        pageOptionsReqr: [5, 10, 15, { value: 100, text: "Show a lot" }],
        sortByReqr: 'timestamp',
        sortDescReqr: true,
        sortDirectionReqr: 'asc',
        filterReqr: null,
        filterOnReqr: [],
        actReqrAttachSealModal: {
          id: 'reqrAttachSeal-modal',
          title: this.$t('matchTx.attachSeal'),
        },
        actReqrUpdateSealModal: {
          id: 'reqrUpdateSeal-modal',
          title: this.$t('matchTx.updateSeal'),
        },
        actReqrTransferModal: {
          id: 'reqrTransfer-modal',
          title: this.$t('matchTx.transfer'),
        },
        totalRowsRels: 1,
        currentPageRels: 1,
        perPageRels: 10,
        pageOptionsRels: [5, 10, 15, { value: 100, text: "Show a lot" }],
        sortByRels: 'timestamp',
        sortDescRels: true,
        sortDirectionRels: 'asc',
        filterRels: null,
        filterOnRels: [],
        imageUriModalReqr: '',
        modalTitleReqr : '',
        imageUriModalRels: '',
        modalTitleRels : ''
      }
    },
    computed: {
      sortOptionsReqr() {
        // Create an options list from our fields
        return this.fieldsReqr
          .filter(f => f.sortable)
          .map(f => {
            return { text: f.label, value: f.key }
          })
      },
      sortOptionsRels() {
        // Create an options list from our fields
        return this.fieldsRels
          .filter(f => f.sortable)
          .map(f => {
            return { text: f.label, value: f.key }
          })
      }
    },
    
    mounted() {
      this.compId= localStorage.getItem("compId")
      matchTxsReqr(this.compId , (res)=>{
        this.itemsReqr = res 
        this.totalRowsReqr = res.length
        // console.log(`[matchTxReqr => ${JSON.stringify(res , null , 2)}]`)
      })
      matchTxsRels(this.compId , (res)=>{
        this.itemsRels = res
        this.totalRowsRels = res.length
        // console.log(`[matchTxRels => ${JSON.stringify(res , null , 2)}]`)
      })
    },
    methods: {
      onFilteredReqr(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRowsReqr = filteredItems.length
        this.currentPageReqr = 1
      },
      onFilteredRels(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRowsRels = filteredItems.length
        this.currentPageRels = 1
      },
      async refreshMatchTx() {
        this.selectedSealNo = ""
        this.selected_mTxId = ""
        await matchTxsReqr(this.compId , (res)=>{
          this.itemsReqr = res
          this.totalRowsReqr = res.length
        })
         await matchTxsRels(this.compId , (res)=>{
          this.itemsRels = res
          this.totalRowsRels = res.length
        })
      },
      async actReqrCancel(item) {
        this.$bvModal.msgBoxConfirm(this.$t('matchTx.confirmCancelMsg'), {
          title: this.$t('matchTx.plsConfirm'),
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: this.$t('matchTx.yes'),
          cancelTitle: this.$t('matchTx.no'),
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true
        })
          .then(async(value) => {
            if (value) {
              // updateStatus('Release', item.relsId, 'Release')// reverse status to "Release"
              item.txSearcher == "Release" ? updateStatus('Require', item.reqrId, 'Require') : updateStatus('Release', item.relsId, 'Release')// return  status of Poster for recycle.
              item.txSearcher == "Release" ? updateContActivebyContNo( item.contNo , true ): null
              await updateMatchTx2StepN('MatchTx',item.mTxId , 1.1).then(async()=>{
                await this.refreshMatchTx()
              })
            } else {
              return
            }
          })
          .catch(error => {
            this.$toasted.show( `Action-Cancel : ${error}`, {theme: 'bubble', type: 'error' , position: 'bottom-center', duration: 10000});  
          })
      },
      actReqrReject(item) {
        this.$bvModal.msgBoxConfirm(this.$t('matchTx.confirmRejectMsg'), {
          title: this.$t('matchTx.plsConfirm'),
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'warning',
          okTitle: this.$t('matchTx.yes'),
          cancelTitle: this.$t('matchTx.no'),
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true
        })
          .then(async(value) => {
            if (value) {
              // updateStatus('Release', item.relsId, 'Release')// reverse status to "Release"
              item.txSearcher == "Release" ? updateStatus('Require', item.reqrId, 'Require') : updateStatus('Release', item.relsId, 'Release')// return  status of Poster for recycle.
              item.txSearcher == "Release" ? updateContActivebyContNo( item.contNo , true ): null
              await updateMatchTx2StepN( 'MatchTx',item.mTxId , 1.2).then(async()=>{
                await this.refreshMatchTx()
              })
            } else {
              return
            }
          })
          .catch(error => {
            this.$toasted.show( `Action-Reject-Require : ${error}`, {theme: 'bubble', type: 'error' , position: 'bottom-center', duration: 10000});  
          })
      },      
      actReqrAccept(item) {
        this.$bvModal.msgBoxConfirm(this.$t('matchTx.confirmAcceptMsg'), {
          title: this.$t('matchTx.plsConfirm'),
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'success',
          okTitle: this.$t('matchTx.yes'),
          cancelTitle: this.$t('matchTx.no'),
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true
        })
          .then(async (value) => {
            if (value) {
              await updateMatchTx2StepN('MatchTx',item.mTxId , 2).then(async()=>{
                await this.refreshMatchTx()
              })
            } else {
              return
            }
          })
          .catch(error => {
            this.$toasted.show( `Action-Accept-Require : ${error}`, {theme: 'bubble', type: 'error' , position: 'bottom-center', duration: 10000});  
          })
      },
      async actReqrAttachSeal(item) {
        console.log(`[actReqrAttachSeal], item =>  ${JSON.stringify(item, null, 2)}`)
        this.selected_mTxId = item.mTxId
        await sealNoListAgentGroup(this.compId, item.agent, (res)=>{
          this.sealNos = res
          this.showSealNoModal=true
        })
      },
      async clickAttachSeal_OK(){
        if (!this.selectedSealNo ) {
          this.$toasted.show( this.$t('matchTx.noSeal'), {theme: 'bubble', type: 'error' , position: 'bottom-center', duration: 10000});  
        } else {
          await updateMatchTx2StepN('MatchTx', this.selected_mTxId , 4 , this.selectedSealNo)
          .then(async()=>{
            await updateActive('Seal' , this.selectedSealNo , false)
            this.showSealNoModal= false
            this.refreshMatchTx()
          })
        }
      },
      async actReqrUpdateSeal(item) {
        console.log(`[actReqrAttachSeal], item =>  ${JSON.stringify(item, null, 2)}`)
        this.selected_mTxId = item.mTxId
        this.currentSealNo = item.sealNo
        await sealNoListAgentGroup(this.compId, item.agent, (res)=>{
          this.sealNos = res
          this.showUpdateSealNoModal=true
        })
      },
      async clickUpdateSeal_OK(){
        if (!this.selectedSealNo ) {
          this.$toasted.show( this.$t('matchTx.noSeal'), {theme: 'bubble', type: 'error' , position: 'bottom-center', duration: 5000});  
        } else {
          await updateSealMatchTx(this.selected_mTxId, this.selectedSealNo)
          .then(async()=>{
            await updateActive('Seal' , this.selectedSealNo , false)
            await updateActive('Seal' , this.currentSealNo , true)
            this.showUpdateSealNoModal= false
            this.refreshMatchTx()
          })
        }
      },
      actReqrTerminate(item){
        this.$bvModal.msgBoxConfirm(this.$t('matchTx.confirmTerminateMsg'), {
          title: this.$t('matchTx.plsConfirm'),
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: this.$t('matchTx.yes'),
          cancelTitle: this.$t('matchTx.no'),
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true
        })
          .then(async(value) => {
            if (value) {
              await updateMatchTx2StepN('MatchTx',item.mTxId , 4.2).then(async()=>{
                await updateContActivebyContNo( item.contNo , true )
                item.sealNo ? await updateActive('Seal' , item.sealNo , true) : null
                await this.refreshMatchTx()
              })
            } else {
              return
            }
          })
          .catch(error => {
            this.$toasted.show( `Action-Terminate : ${error}`, {theme: 'bubble', type: 'error' , position: 'bottom-center', duration: 10000});  
          })
      },

      async actReqrTransfer(item) {
        // console.log(`[actReqrTransfer], item =>  ${JSON.stringify(item, null, 2)}`)
        this.selected_mTxId = item.mTxId
        await bkgIdListAgentGroup(this.compId, item.agent, item.bkgId,(res)=>{
          this.bkgIds = res
          this.showTransferModal=true
        })
      },
      async clickBkgId_OK(){
        if (!this.selectedBkgId ) {
          this.$toasted.show( this.$t('matchTx.noBkgId'), {theme: 'bubble', type: 'error' , position: 'bottom-center', duration: 10000});  
        } else {
          await makeMatchTxTrStep2(this.selected_mTxId , this.selectedBkgId).then(async()=>{   // make new MatchTx - Transfer for ship approval 
            await updateMatchTx2StepN('MatchTx', this.selected_mTxId , 4.1 , this.selectedBkgId)  // step up current MatchTx to step 4.1 as status: cancel , jbreqr: transfer
            this.showTransferModal= false
            this.refreshMatchTx()    
          })
        }
      },
      openModalImageReqr(item, index, button) {
            this.imageUriModalReqr = item.imageUri
            this.modalTitleReqr = item.contNo
            this.$root.$emit('bv::show::modal', "contModalReqr" , button)
        },
      resetInfoModalReqr() {
        this.modalTitleReqr = ''
        this.imageUriModalReqr = ''
      },
      openModalImageRels(item, index, button) {
            this.imageUriModalRels = item.imageUri
            this.modalTitleRels = item.contNo
            this.$root.$emit('bv::show::modal', "contModalRels" , button)
        },
      resetInfoModalRels() {
        this.modalTitleRels = ''
        this.imageUriModalRels = ''
      },
      async exportItems (compId , startAt, endAt, cb ){
        await xlsxRecvr( compId, startAt , endAt, async(reqr)=>{
          await xlsxPropsr( compId, startAt , endAt, async(rels)=>{
            let i = reqr.concat(rels)
            await xlsxInternal( compId, startAt , endAt, async(reu)=>{
              i = i.concat(reu)
              await xlsxTransfer(compId, startAt, endAt, (trn)=>{
                i = i.concat(trn)
                cb(i)
              })
            })
          })
        })
      },
      async back7days(){
        let endAt = Date.parse(new Date())
        let startAt =  endAt - 604800000 
        let name = `conex_${this.$moment(startAt).format("YYYY-MM-DD")}_${this.$moment(endAt).format("YYYY-MM-DD")}`
        try {
          this.exportItems(this.compId, startAt, endAt , (res)=>{
            exportXlsx(res, name)
            // console.log("[exportItems] res =>"+ JSON.stringify(res, null , 2)) 
          })
        } catch (error) {
          this.$toasted.show(`${this.$t('matchTx.errExportData')}: ${error}`, {theme: 'bubble', type: 'error' , position: 'bottom-center', duration: 5000});     
        }
      },
      async back30days(){
        let endAt = Date.parse(new Date())
        let startAt =  endAt - 18446400000 
        let name = `conex_${this.$moment(startAt).format("YYYY-MM-DD")}_${this.$moment(endAt).format("YYYY-MM-DD")}`
        try {
          this.exportItems(this.compId, startAt, endAt , (res)=>{
            exportXlsx(res, name)
            // console.log("[exportItems] res =>"+ JSON.stringify(res, null , 2)) 
          })
        } catch (error) {
          this.$toasted.show(`${this.$t('matchTx.errExportData')}: ${error}`, {theme: 'bubble', type: 'error' , position: 'bottom-center', duration: 5000});     
        }
      },
      async inYear(){
        let endAt = Date.parse(new Date())
        let startAt = Date.parse(new Date("2024/01/01"))
        let name = `conex_${this.$moment(startAt).format("YYYY-MM-DD")}_${this.$moment(endAt).format("YYYY-MM-DD")}`
        try {
          this.exportItems(this.compId, startAt, endAt , (res)=>{
            exportXlsx(res, name)
            // console.log("[exportItems] res =>"+ JSON.stringify(res, null , 2)) 
          })
        } catch (error) {
          this.$toasted.show(`${this.$t('matchTx.errExportData')}: ${error}`, {theme: 'bubble', type: 'error' , position: 'bottom-center', duration: 5000});     
        }
      },
    }
  }
</script>